
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'appraiser-appraisals-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('appraisalsRequestsUpdating'), [
          translate('appraisals'),
        ]);
      });

      const { data } = await store.dispatch(Actions.GET_APPRAISAL, {
        id: route.params.id,
        isAppraiser: true,
      });

      const appraisal = ref(data.data);

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const appraisalSchema = Yup.object().shape({
        status: Yup.number()
          .required(() => translate('REQUEST_STATUS_IS_REQUIRED_FIELD'))
          .label('Appraisal Request Status'),
      });

      const onSubmitUpdate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const payload = { status: Number(values.status) };
        await store.dispatch(Actions.UPDATE_APPRAISAL, {
          id: appraisal.value?.id,
          data: payload,
          isAppraiser: true,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_APPRAISAL_REQUEST'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({
              name: 'appraiser-appraisals-viewing',
              params: { id: appraisal.value?.id },
            });
          });
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        onSubmitUpdate,
        submitButton,
        translate,
        appraisalSchema,
        goBack,
        appraisal,
        can,
      };
    },
  });
